import React, { useState } from 'react';
import {
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  TextField
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddRoleData = () => {
  const navigate = useNavigate();
  const [roleData, setRoleData] = useState({
    name: '',
    outletId: '',
    permissions: {
      userProfile: { create: true, read: true, view: true, delete: true },
      dashboard: { create: false, read: false, view: false, delete: false },
      forecast: { create: false, read: false, view: false, delete: false },
      users: { create: false, read: false, view: false, delete: false },
      customerLoan: { create: false, read: false, view: false, delete: false },
      customerCash: { create: false, read: false, view: false, delete: false },
      paymentTerms: { create: false, read: false, view: false, delete: false },
      bikeModel: { create: false, read: false, view: false, delete: false },
      score: { create: false, read: false, view: false, delete: false },
      stock: { create: false, read: false, view: false, delete: false },
      role: { create: false, read: false, view: false, delete: false },
      activity: { create: false, read: false, view: false, delete: false },
    },
  });
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const apiURL = process.env.REACT_APP_API_URL;

  // Handle input change for name and outletId
  const handleInputChange = (field, value) => {
    setRoleData({ ...roleData, [field]: value });
  };

  // Handle individual permission change for each category
  const handlePermissionChange = (category, permissionType) => {
    setRoleData((prevState) => ({
      ...prevState,
      permissions: {
        ...prevState.permissions,
        [category]: {
          ...prevState.permissions[category],
          [permissionType]: !prevState.permissions[category][permissionType],
        },
      },
    }));
  };

  // Handle saving the role data to the API
  const handleSaveRoleData = async () => {
    try {
      const storedToken = localStorage.getItem('token');
      if (!storedToken) {
        throw new Error('No token found, authorization required');
      }

      console.log('Role Data:', roleData); // Verify roleData before sending
      const response = await axios.post(`${apiURL}/roles/create`, roleData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      });

      console.log('Role added:', response.data);
      setSnackbarMessage('Role added successfully!');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error adding role:', error);
      setError('Failed to add role. Please try again.');
    }
  };

  // Close Snackbar and redirect
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    navigate('/roles'); // Redirect after Snackbar closes
  };

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ px: 2 }}
    >
      <Box
        width="100%"
        maxWidth="800px"
        p={3}
        boxShadow={3}
        borderRadius={2}
        bgcolor="background.paper"
      >
        <Typography variant="h4" align="center" gutterBottom>
          Add New Role
        </Typography>
        <form>
          <TextField
            label="Role Name"
            value={roleData.name}
            onChange={(e) => handleInputChange('name', e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Outlet ID"
            value={roleData.outletId}
            onChange={(e) => handleInputChange('outletId', e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <Typography variant="h6" gutterBottom>Permissions</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Module</TableCell>
                  <TableCell align="center">Create</TableCell>
                  <TableCell align="center">Read</TableCell>
                  <TableCell align="center">View</TableCell>
                  <TableCell align="center">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(roleData.permissions).map((category) => (
                  <TableRow key={category}>
                    <TableCell>{category}</TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={roleData.permissions[category].create}
                        onChange={() => handlePermissionChange(category, 'create')}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={roleData.permissions[category].read}
                        onChange={() => handlePermissionChange(category, 'read')}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={roleData.permissions[category].view}
                        onChange={() => handlePermissionChange(category, 'view')}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={roleData.permissions[category].delete}
                        onChange={() => handlePermissionChange(category, 'delete')}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </form>
        <Box display="flex" justifyContent="center" mt={3}>
          <Button onClick={handleSaveRoleData} variant="contained" style={{ backgroundColor: '#2c9294' }}>
            Add Role
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ top: 0 }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddRoleData;
