import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField // Add TextField for search input
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { formatDate } from '../../utils/formatDate'; // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom';

const StockData = () => {
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedModelId, setSelectedModelId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // State to hold the search term
  const navigate = useNavigate();
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchStocks = async () => {
      try {
        const storedToken = localStorage.getItem('token');

        const response = await axios.get(`${apiURL}/stocks`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          }
        });
        setStocks(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStocks();
  }, [apiURL]);

  const handleMenuOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedModelId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedModelId(null);
  };

  const handleEdit = () => {
    if (selectedModelId) {
      navigate(`/stocks/${selectedModelId}`);
    }
    handleMenuClose();
  };

  const handleDeleteConfirmation = () => {
    setOpenDialog(true);
  };

  const handleDeleteCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDelete = async () => {
    if (selectedModelId) {
      try {
        const storedToken = localStorage.getItem('token');
        await axios.delete(`${apiURL}/stocks/${selectedModelId}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setStocks((prevStocks) =>
          prevStocks.filter((stock) => stock._id !== selectedModelId)
        );
      } catch (err) {
        console.error('Error deleting stock:', err);
      } finally {
        handleMenuClose();
        handleDeleteCloseDialog();
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter stocks based on search term for selected fields
  const filteredStocks = stocks.filter((stock) => {
    const supplier = stock.supplier?.toLowerCase() || '';
    const manufacture = stock.stockManufacturing?.toLowerCase() || '';
    const model = stock.stockModel?.toLowerCase() || '';
    const variants = stock.stockVariants?.toLowerCase() || '';
    const color = stock.stockColour?.toLowerCase() || '';
    const engineNo = stock.engineNumber?.toLowerCase() || '';
    const cc = stock.cc ? stock.cc.toString() : ''; 

    return (
      supplier.includes(searchTerm.toLowerCase()) ||
      manufacture.includes(searchTerm.toLowerCase()) ||
      model.includes(searchTerm.toLowerCase()) ||
      variants.includes(searchTerm.toLowerCase()) ||
      color.includes(searchTerm.toLowerCase()) ||
      engineNo.includes(searchTerm.toLowerCase()) ||
      cc.includes(searchTerm)
    );
  });

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <div>
      <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="0px">
        <TextField
          label="Search by  Supplier, Manufacture, Model, Variants, Color, Engine No, or CC"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
          style={{ width: '400px', marginLeft: '10px',marginTop: '10px'  }} // Adjust width and styling as necessary
        />
        <Button
          variant="contained"
          color="primary"
          href="/stocks/AddStock"
          style={{ backgroundColor: '#2c9294', marginRight: '10px' }}
        >
          Add Stock
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Supplier</TableCell>
            <TableCell>Manufacture</TableCell>
            <TableCell>Model</TableCell>
            <TableCell>Variants</TableCell>
            <TableCell>Color</TableCell>
            <TableCell>Engine No.</TableCell>
            <TableCell>Chassis No.</TableCell>
            <TableCell>Condition</TableCell>
            <TableCell>In Date</TableCell>
            <TableCell>Out Date</TableCell>
            <TableCell>CC</TableCell>
            <TableCell>Vendor Price</TableCell>
            <TableCell>Sales Price</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredStocks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((model, index) => (
            <TableRow key={model._id}>
              <TableCell>{page * rowsPerPage + index + 1}</TableCell> {/* Running number */}
              <TableCell>{model.supplier}</TableCell>
              <TableCell>{model.stockManufacturing}</TableCell>
              <TableCell>{model.stockModel}</TableCell>
              <TableCell>{model.stockVariants}</TableCell>
              <TableCell>{model.stockColour}</TableCell>
              <TableCell>{model.engineNumber}</TableCell>
              <TableCell>{model.chassisNumber}</TableCell>
              <TableCell>{model.condition}</TableCell>
              <TableCell>{formatDate(model.stockInDate)}</TableCell>
              <TableCell>{formatDate(model.stockOutDate)}</TableCell>
              <TableCell>{model.cc}</TableCell>
              <TableCell>{model.vendorPrice}</TableCell>
              <TableCell>{model.sellingPrice}</TableCell>
              <TableCell>{formatDate(model.createdAt)}</TableCell>
              <TableCell>{formatDate(model.updatedAt)}</TableCell>
              <TableCell>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={(event) => handleMenuOpen(event, model._id)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl) && selectedModelId === model._id}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleEdit}>Edit</MenuItem>
                  <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={filteredStocks.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog
        open={openDialog}
        onClose={handleDeleteCloseDialog}
      >
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this stock? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StockData;
