import React, { useState } from 'react';
import { Tabs, Tab, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomerLoan from './loan/CustomerLoan';
import EmploymentLoan from './loan/EmploymentLoan';
import ReferenceLoan from './loan/ReferenceLoan';
import FinanceLoan from './loan/FinanceLoan';
import AttachmentLoan from './loan/AttachmentLoan';

const useStyles = makeStyles({
  Button: {
    backgroundColor: '#037b7d',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2c9294',
    },
  },
});

const ViewCustomerLoan = () => {
  const [tabValue, setTabValue] = useState(0);
  const classes = useStyles();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between" // To separate the tabs and button
        marginBottom="5px" // Add spacing below the header
      >
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs">
          <Tab label="Customer Loan" />
          <Tab label="Employment" />
          <Tab label="References" />
          <Tab label="Finances" />
          <Tab label="Attachments" />
        </Tabs>
        
        <Button
          variant="contained"
          className={classes.Button}
          href="/customerloans/addcustomerloan"
          style={{ backgroundColor: '#2c9294', marginRight:'10px' }}
        >
          Add Loan Customer
        </Button>
      </Box>

      {/* Content depending on tab */}
      {tabValue === 0 && <CustomerLoan />}
      {tabValue === 1 && <EmploymentLoan />}
      {tabValue === 2 && <ReferenceLoan />}
      {tabValue === 3 && <FinanceLoan />}
      {tabValue === 4 && <AttachmentLoan />}
    </div>
  );
};

export default ViewCustomerLoan;
