import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const useStyles = makeStyles({
  Button: {
    backgroundColor: '#037b7d',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2c9294',
    },
  },
});

const fetchData = async (url, setData) => {
  try {
    const response = await axios.get(url);
    setData(response.data);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const calculateProgress = (attachment) => {
  const statusPoints = {
    approved: 20,
    pending: 0,
    rejected: 0
  };
  const keys = ['incomeStatement', 'salarySlip', 'epfStatement', 'utilitiesBill', 'ctosReport'];
  let totalProgress = 0;

  keys.forEach((key) => {
    totalProgress += statusPoints[attachment[key].status];
  });

  return totalProgress;
};

const RenderTable = ({ data, columns, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, handleMenuOpen, anchorEl, selectedRow, handleMenuClose, handleEdit }) => {
  const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell key={col.field}>{col.headerName}</TableCell>
            ))}
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData.map((item) => (
            <TableRow key={item._id}>
              {columns.map((col) => (
                <TableCell key={col.field}>
                  {col.field === 'progress' ? (
                    calculateProgress(item)
                  ) : col.render ? (
                    col.render(item[col.field], item)
                  ) : (
                    item[col.field]?.toString()
                  )}
                </TableCell>
              ))}
              <TableCell>
                <IconButton onClick={(e) => handleMenuOpen(e, item)}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && selectedRow === item}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => handleEdit(item._id)}>Edit</MenuItem>
                  
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

const AttachmentLoan = () => {
  const [attachments, setAttachments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const apiURL = process.env.REACT_APP_API_URL;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchAttachments = async () => {
      try {
        setLoading(true);
        const storedToken = localStorage.getItem('token');

        const response = await axios.get(`${apiURL}/attachmentsloans/my-attach`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          }
        });

        const transformData = (data) => {
          return data.map(item => ({
            ...item,
            customerName: item.customerId ? item.customerId.name : 'Unknown',
          }));
        };

        const transformedData = transformData(response.data);
        setAttachments(transformedData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAttachments();
  }, [apiURL]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = (id) => {
    console.log('attachment', id)
    navigate(`/customerloans/attachments/${id}`);
  };

  const attachmentColumns = [
    { field: 'customerName', headerName: 'Customer' },
    {
      field: 'nric.front',
      headerName: 'NRIC Front',
      render: (value, row) => {
        return row.nric?.front ? (
          <a href={row.nric.front} target="_blank" rel="noopener noreferrer">
            <Button variant="contained"  style={{backgroundColor:'rgb(44, 146, 148)',textTransform:'capitalize'}}>NRIC Front </Button> 
          </a>
        ) : 'N/A';
      }
    },
    {
      field: 'nric.back',
      headerName: 'NRIC Back',
      render: (value, row) => {
        return row.nric?.back ? (
          <a href={row.nric.back} target="_blank" rel="noopener noreferrer">
           <Button variant="contained"  style={{backgroundColor:'rgb(44, 146, 148)',textTransform:'capitalize'}}> NRIC Back</Button> 
          </a>
        ) : 'N/A';
      }
    },
    {
      field: 'licence.front',
      headerName: 'Licence Front',
      render: (value, row) => {
        return row.licence?.front ? (
          <a href={row.licence.front} target="_blank" rel="noopener noreferrer">
            <Button variant="contained"  style={{backgroundColor:'rgb(44, 146, 148)',textTransform:'capitalize'}}>Licence Front</Button> 
          </a>
        ) : 'N/A';
      }
    },
    {
      field: 'licence.back',
      headerName: 'Licence Back',
      render: (value, row) => {
        return row.licence?.back ? (
          <a href={row.licence.back} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <Button variant="contained"  style={{backgroundColor:'rgb(44, 146, 148)',textTransform:'capitalize'}}>Licence Back</Button>
          </a>
        ) : 'N/A';
      }
    },
    {
      field: 'incomeStatement.path',
      headerName: 'Income Statement',
      render: (value, row) => {
        return (
          <div>
            {row.incomeStatement?.path ? (
              <a href={row.incomeStatement.path} target="_blank" rel="noopener noreferrer">
                <Button variant="contained"  style={{backgroundColor:'rgb(44, 146, 148)', textTransform:'capitalize'}}> Income Statement</Button>
              </a>
            ) : 'N/A'}
            <div>Status: {row.incomeStatement?.status}</div>
          </div>
        );
      }
    },
    {
      field: 'salarySlip.path',
      headerName: 'Salary Slip',
      render: (value, row) => {
        return (
          <div>
            {row.salarySlip?.path ? (
              <a href={row.salarySlip.path} target="_blank" rel="noopener noreferrer">
               <Button variant="contained"  style={{backgroundColor:'rgb(44, 146, 148)',textTransform:'capitalize'}}> Salary Slip</Button> 
              </a>
            ) : 'N/A'}
            <div>Status: {row.salarySlip?.status}</div>
          </div>
        );
      }
    },
    {
      field: 'epfStatement.path',
      headerName: 'EPF Statement',
      render: (value, row) => {
        return (
          <div>
            {row.epfStatement?.path ? (
              <a href={row.epfStatement.path} target="_blank" rel="noopener noreferrer">
                <Button variant="contained"  style={{backgroundColor:'rgb(44, 146, 148)',textTransform:'capitalize'}}>EPF Statement </Button> 
              </a>
            ) : 'N/A'}
            <div>Status: {row.epfStatement?.status}</div>
          </div>
        );
      }
    },
    {
      field: 'utilitiesBill.path',
      headerName: 'Utilities Bill',
      render: (value, row) => {
        return (
          <div>
            {row.utilitiesBill?.path ? (
              <a href={row.utilitiesBill.path} target="_blank" rel="noopener noreferrer">
               <Button variant="contained"  style={{backgroundColor:'rgb(44, 146, 148)', textTransform:'capitalize'}}>Utilities Bill </Button> 
              </a>
            ) : 'N/A'}
            <div>Status: {row.utilitiesBill?.status}</div>
          </div>
        );
      }
    },
    {
      field: 'ctosReport.path',
      headerName: 'CTOS Report',
      render: (value, row) => {
        return (
          <div>
            {row.ctosReport?.path ? (
              <a href={row.ctosReport.path} target="_blank" rel="noopener noreferrer">
               <Button variant="contained"  style={{backgroundColor:'rgb(44, 146, 148)', textTransform:'capitalize'}}>CTOS Report</Button> 
              </a>
            ) : 'N/A'}
            <div>Status: {row.ctosReport?.status}</div>
          </div>
        );
      }
    },
    {
      field: 'scores',
      headerName: 'Scores',
      render: (value, row) => `${row.totalScore}/${row.totalOutOfScore}`
    },
   
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <RenderTable
      data={attachments}
      columns={attachmentColumns}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleMenuOpen={handleMenuOpen}
      anchorEl={anchorEl}
      selectedRow={selectedRow}
      handleMenuClose={handleMenuClose}
      handleEdit={handleEdit} // Pass handleEdit function as a prop
    />
  );
};

export default AttachmentLoan;
