import React, { useEffect, useState } from 'react';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import {jwtDecode} from 'jwt-decode';
import { Card, CardContent, Typography } from '@mui/material';
import './home.scss';


const UserProfile = () => {
  const [user, setUser]= useState({username:'', role:'',email:''});
  const apiURL = process.env.REACT_APP_API_URL ;
  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await fetch(`${apiURL}/users/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
  
        // Set user with id, username, email, and role name
        setUser({
          id: data._id, // Include the id field here
          username: data.username,
          email: data.email,
          role: data.role.name,  // Here, we only take the role name
        });
  
        console.log('User profile data', data);
      } catch (error) {
        console.log('Error fetching user data', error);
      }
    };
  
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      const decodedToken = jwtDecode(storedToken);
      console.log('Decoded Token:', decodedToken);
  
      // Correctly access the id
      const userId = decodedToken.id;
      console.log('User ID:', userId);
  
      if (userId) {
        fetchUserData(userId);
      } else {
        console.error('User ID is undefined in the token.');
      }
    }
  }, [apiURL]);
  

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <Card style={{ maxWidth: 600, margin: 'auto', marginTop: 50 }}>
          <CardContent>
            <Typography variant="h5" component="div">
              User Profile
            </Typography>
            <Typography variant="body2" color="text.secondary">
              ID: {user.id || 'Not available'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Name: {user.username}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Email: {user.email}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Role: {user.role}
            </Typography>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default UserProfile;