import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import the autoTable plugin
import { Button } from '@mui/material';

const DownloadCustomerDetails = ({
    customerDetails,
    employmentDetails,
    referencesDetails,
    financesDetails,
    attachmentsDetails,
    paymentTerms,
    scoresDetails,
    auditTrailDetails, // Include audit trail details in props
}) => {
    const handlePdfExport = () => {
        const doc = new jsPDF();

        // Customer Details Table
        if (customerDetails) {
            doc.setFontSize(16);
            doc.text('Customer Details', 10, 10);

            const customerData = [
                ['ID', customerDetails._id],
                ['Name', customerDetails.name],
                ['NRIC No', customerDetails.nricNo],
                ['Number Plate', customerDetails.NoPlate],
                ['Mobile', customerDetails.mobileNumber],
                ['Email', customerDetails.email],
                ['Best Time to Call', customerDetails.bestTimeToCall],
                ['Gender', customerDetails.gender],
                ['Marital Status', customerDetails.maritalStatus],
                ['Address', customerDetails.address],
                ['Race', customerDetails.race],
                ['City', customerDetails.city],
                ['Postcode', customerDetails.postcode],
                ['State', customerDetails.state],
                ['Status', customerDetails.status],
                ['Created By Username', customerDetails.createdByUsername],
                ['Created At', new Date(customerDetails.createdAt).toLocaleDateString()],
                ['Updated At', new Date(customerDetails.updatedAt).toLocaleDateString()],
            ];

            doc.autoTable({
                head: [['Field', 'Value']],
                body: customerData,
                startY: 20,
            });
        }

        // Employment Details Table
        if (employmentDetails.length > 0) {
            doc.addPage();
            doc.setFontSize(16);
            doc.text('Employment Details', 10, 10);

            const employmentData = employmentDetails.map((employment) => [
                ['Occupation', employment.occupation],
                ['Employer Name', employment.employerName],
                ['Gross Salary', employment.monthlyGrossSalary],
                ['Net Salary', employment.monthlyNetSalary],
                ['Employment Length', employment.employmentLength],
                ['City', employment.city],
                ['Postcode', employment.postcode],
                ['State', employment.state],
                ['Employer Phone', employment.employerPhoneNumber],
                ['Monthly Gross Salary', employment.monthlyGrossSalary],
                ['Net Salary', employment.monthlyNetSalary],
            ]);

            employmentData.forEach((data, index) => {
                doc.autoTable({
                    head: [['Field', 'Value']],
                    body: data,
                    startY: index === 0 ? 20 : undefined, // Keep adding tables for each record
                });
            });
        }

        // References Table
        if (referencesDetails.length > 0) {
            doc.addPage();
            doc.setFontSize(16);
            doc.text('References', 10, 10);

            const referenceData = referencesDetails.map((reference) => [
                ['Reference Name', reference.name],
                ['Relationship', reference.relationship],
                ['nricNo', reference.nricNo],
                ['Contact Number', reference.contactNumber],
            ]);

            referenceData.forEach((data, index) => {
                doc.autoTable({
                    head: [['Field', 'Value']],
                    body: data,
                    startY: index === 0 ? 20 : undefined,
                });
            });
        }

        // Finances Table
        if (financesDetails.length > 0) {
            doc.addPage();
            doc.setFontSize(16);
            doc.text('Finances', 10, 10);

            const financeData = financesDetails.map((finance) => [
                ['Product', finance.product],
                ['Commitment', finance.customerCommitment],
                ['Condition', finance.condition],
                ['Bike Manufacturing', finance.model?.manufacturing],
                ['Bike Model', finance.model?.model],
                ['Bike Variants', finance.model?.variants],
                ['Bike Colour', finance.model?.color],
                ['Stock Manufacturing', finance.stockManufacturing],
                ['Stock Model', finance.stockModel],
                ['Stock Variants', finance.stockVariants],
                ['Stock Colour', finance.stockColour],
                ['Sales Price', finance.salePrice],
                ['Financing Amount', finance.financingAmount],
                ['Deposit', finance.deposit],
                ['Tenure', finance.tenure],
                ['Monthly Installment Amount', finance.monthlyInstalmentAmount],
            ]);

            financeData.forEach((data, index) => {
                doc.autoTable({
                    head: [['Field', 'Value']],
                    body: data,
                    startY: index === 0 ? 20 : undefined,
                });
            });
        }

        // Attachments Table
        if (attachmentsDetails.length > 0) {
            doc.addPage();
            doc.setFontSize(16);
            doc.text('Attachments', 10, 10);

            const attachmentData = attachmentsDetails.map((attachment) => [
                ['NRIC Front', attachment.nric.front],
                ['NRIC Back', attachment.nric.back],
                ['LICENSE Front', attachment.licence.front],
                ['LICENSE Back', attachment.licence.back],
                ['Salary Slip', attachment.salarySlip.path],
                ['Income Statement', attachment.incomeStatement.path],
                ['EPF Statement', attachment.epfStatement.path],
                ['Utility Bill', attachment.utilitiesBill.path],
                ['CTOS Report', attachment.ctosReport.path],
            ]);

            attachmentData.forEach((data, index) => {
                doc.autoTable({
                    head: [['Field', 'Value']],
                    body: data,
                    startY: index === 0 ? 20 : undefined,
                });
            });
        }

        // Payment Terms Table
        if (paymentTerms.length > 0) {
            doc.addPage();
            doc.setFontSize(16);
            doc.text('Payment Terms', 10, 10);

            paymentTerms.forEach((term, index) => {
                const paymentTermData = [
                    ['Customer Name', term.customerName],
                    ['NIC', term.NIC],
                    ['Status', term.status],
                    ['Financing Amount', term.financingAmount],
                    ['Tenure', term.tenure],
                    ['Paid Installments', term.paidInstallment],
                    ['Unpaid Installments', term.unpaidInstallment],
                    ['Overdue Installments', term.overdueInstallment],
                    ['Monthly Amount', term.monthlyAmount],
                ];

                // Create a table for each payment term
                doc.autoTable({
                    head: [['Field', 'Value']],
                    body: paymentTermData,
                    startY: index === 0 ? 20 : undefined, // Start position for first table
                });

                // Payment Dates Section for this term
                if (term.paymentDates && term.paymentDates.length > 0) {
                    doc.text('Payment Dates', 10, doc.autoTable.previous.finalY + 10); // Label for Payment Dates

                    const paymentDatesData = term.paymentDates.map((paymentDate) => [
                        new Date(paymentDate.dueDate).toLocaleDateString(),
                        paymentDate.status,
                        paymentDate.paidDate ? new Date(paymentDate.paidDate).toLocaleDateString() : 'Not Paid',
                    ]);

                    // Add a table for the Payment Dates under the current term
                    doc.autoTable({
                        head: [['Due Date', 'Status', 'Paid Date']],
                        body: paymentDatesData,
                        startY: doc.autoTable.previous.finalY + 20, // Start position for payment dates table
                    });
                }
            });
        }

        // Scores Table with Audit Trail
        if (scoresDetails.length > 0) {
            doc.addPage();
            doc.setFontSize(16);
            doc.text('Scores', 10, 10);

            const scoreData = scoresDetails.map((score) => [
                ['Title', score.title],
                ['Score', `${score.score} / ${score.scoreOutOf}`],
            ]);

            scoreData.forEach((data, index) => {
                doc.autoTable({
                    head: [['Field', 'Value']],
                    body: data,
                    startY: index === 0 ? 20 : undefined,
                });
            });

            // Audit Trail Section (Only if there are audit trail details)
            if (auditTrailDetails.length > 0) {
                doc.text('Audit Trail', 10, doc.autoTable.previous.finalY + 10); // Add a label for the audit trail

                const auditTrailData = auditTrailDetails.map((audit) => [
                    ['Scored By', audit.username],
                    ['Created By', customerDetails.createdByUsername],  // Assuming you want to show who created the entry
                ]);

                auditTrailData.forEach((data, index) => {
                    doc.autoTable({
                        head: [['Field', 'Value']],
                        body: data,
                        startY: index === 0 ? doc.autoTable.previous.finalY + 20 : undefined, // Ensure the Audit Table is positioned after the scores table
                    });
                });
            }
        }

        doc.save('customer-details.pdf');
    };

    return (
        <Button variant="contained" color="primary" onClick={handlePdfExport}>
            Export to PDF
        </Button>
    );
};

export default DownloadCustomerDetails;
