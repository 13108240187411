import React, { useState, useEffect } from 'react';
import axios from 'axios';

const FinancingSummary = () => {
  const [financingSummaryByYear, setFinancingSummaryByYear] = useState({});
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const apiURL = process.env.REACT_APP_API_URL; // Use your fixed API URL here
  // const apiURL = 'http://192.168.0.139:5000/api/financeloans/state/financing'; // Use your fixed API URL here

  // Fetch financing data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiURL}/financeloans/state/financing`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`, // assuming token is stored in localStorage
          },
        });
        setFinancingSummaryByYear(response.data.financingAmountSummary);
      } catch (error) {
        console.error('Error fetching financing data:', error);
      }
    };

    fetchData();
  }, []);

  // Handle year selection change
  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value, 10));
  };

  // Table component to display the data
  const MonthlyPaymentsTable = ({ data }) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ];

    return (
      <table className="monthlyPaymentsTable" border="1">
        <thead>
          <tr>
            <th>Month</th>
            <th>Financing Amount</th>
            <th>Total Amount</th>
          </tr>
        </thead>
        <tbody>
          {months.map((month) => (
            <tr key={month}>
              <td>{month}</td>
              <td>{data[month]?.currentMonth?.toFixed(2) || '0.00'}</td>
              <td>{data[month]?.cumulative?.toFixed(2) || '0.00'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="financing-summary">
      <h2>Financing Amount</h2>
      <div className="yearSelector">
        <label htmlFor="year">Select Year: </label>
        <select id="year" value={selectedYear} onChange={handleYearChange}>
          {Object.keys(financingSummaryByYear).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <MonthlyPaymentsTable data={financingSummaryByYear[selectedYear] || {}} />
    </div>
  );
};

export default FinancingSummary;
