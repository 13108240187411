import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Alert,
  Grid,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';

const EditFinanceStockLoan = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [financeData, setFinanceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({});
  const [saving, setSaving] = useState(false);

  // State for API responses
  const [bikeModels, setBikeModels] = useState([]);
  const [filteredModels, setFilteredModels] = useState([]);
  const [filteredVariants, setFilteredVariants] = useState([]);
  const [filteredBikeColours, setFilteredBikeColours] = useState([]);
  const [filteredStockColours, setFilteredStockColours] = useState([]);
  const [engines, setEngines] = useState([]);
  const [chassisNumbers, setChassisNumbers] = useState([]);
  const [filteredChassisNumbers, setFilteredChassisNumbers] = useState([]);
  const [filteredManufacturing, setFilteredManufacturing] = useState([]);
  const [filteredStockModels, setFilteredStockModels] = useState([]);
  const [filteredStockVariants, setFilteredStockVariants] = useState([]);

  // Separate state for Stock model
  const [stockFormData, setStockFormData] = useState({
    engine: '',
    chassisNo: '',
    stockManufacturing: '',
    stockModel: '',
    stockVariants: '',
    stockColour: '',
    NoPlate: ''
  });

  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchBikeModels = async () => {
      try {
        const response = await axios.get(`${apiURL}/bikemodels`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setBikeModels(response.data);
      } catch (error) {
        console.error('Error fetching bike models:', error);
      }
    };

    const fetchStockData = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/stocks`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          }
        });
        const stockData = response.data;

        setFilteredManufacturing([...new Set(stockData.map(stock => stock.stockManufacturing))]);
        setFilteredStockModels([...new Set(stockData.map(stock => stock.stockModel))]);
        setFilteredStockVariants([...new Set(stockData.map(stock => stock.stockVariants))]);
        setFilteredStockColours([...new Set(stockData.map(stock => stock.stockColour))]);
        setEngines([...new Set(stockData.map(stock => stock.engineNumber))]);
        setChassisNumbers(stockData);
      } catch (error) {
        console.error('Error fetching stock data:', error);
      }
    };

    const fetchFinanceData = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/financeloans/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        const financeData = response.data;
        setFinanceData(financeData);
        setFormData(financeData);

        setStockFormData((prevState) => ({
          ...prevState,
          engine: financeData.engine || '',
          chassisNo: financeData.chassisNo || '',
          stockManufacturing: financeData.stockManufacturing || '',
          stockModel: financeData.stockModel || '',
          stockVariants: financeData.stockVariants || '',
          stockColour: financeData.stockColour || '',
          NoPlate: financeData.NoPlate || '',
        }));
      } catch (error) {
        console.error('Error fetching finance data:', error);
        setError('Failed to load finance data');
      } finally {
        setLoading(false);
      }
    };

    fetchBikeModels();
    fetchStockData();
    fetchFinanceData();
  }, [id, apiURL]);

  useEffect(() => {
    if (stockFormData.stockManufacturing) {
      const filteredStockModels = chassisNumbers
        .filter(stock => stock.stockManufacturing === stockFormData.stockManufacturing)
        .map(stock => stock.stockModel);
      setFilteredStockModels([...new Set(filteredStockModels)]);
    }
  }, [stockFormData.stockManufacturing, chassisNumbers]);

  useEffect(() => {
    if (stockFormData.stockModel) {
      const filteredStockVariants = chassisNumbers
        .filter(stock => stock.stockModel === stockFormData.stockModel)
        .map(stock => stock.stockVariants);
      setFilteredStockVariants([...new Set(filteredStockVariants)]);
    }
  }, [stockFormData.stockModel, chassisNumbers]);

  useEffect(() => {
    if (stockFormData.stockManufacturing && stockFormData.stockModel && stockFormData.stockVariants) {
      const filteredColours = chassisNumbers
        .filter(stock =>
          stock.stockManufacturing === stockFormData.stockManufacturing &&
          stock.stockModel === stockFormData.stockModel &&
          stock.stockVariants === stockFormData.stockVariants)
        .map(stock => stock.stockColour);
      setFilteredStockColours([...new Set(filteredColours)]);
    }
  }, [stockFormData.stockManufacturing, stockFormData.stockModel, stockFormData.stockVariants, chassisNumbers]);

  useEffect(() => {
    if (stockFormData.stockColour) {
      const filteredEngines = chassisNumbers
        .filter(stock => stock.stockColour === stockFormData.stockColour)
        .map(stock => stock.engineNumber);
      setEngines([...new Set(filteredEngines)]);
    }
  }, [stockFormData.stockColour, chassisNumbers]);

  useEffect(() => {
    if (stockFormData.engine) {
      const filteredChassis = chassisNumbers
        .filter(stock => stock.engineNumber === stockFormData.engine)
        .map(stock => stock.chassisNumber);
      setFilteredChassisNumbers(filteredChassis);

      if (filteredChassis.length === 1) {
        setStockFormData(prevState => ({
          ...prevState,
          chassisNo: filteredChassis[0]
        }));
      }
    }
  }, [stockFormData.engine, chassisNumbers]);

  const handleStockChange = (e) => {
    const { name, value } = e.target;
    setStockFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      const storedToken = localStorage.getItem('token');
      await axios.put(`${apiURL}/financeloans/${id}`, {
        ...formData,
        ...stockFormData
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      });
      navigate(`/customerloans`);
    } catch (error) {
      console.error('Error updating finance data:', error);
      setError('Failed to update finance data');
    } finally {
      setSaving(false);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h4" gutterBottom>
          Edit Stock and View Bike Model
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            {/* Bike Model Section (Read-only) */}
            <Grid item xs={12} style={{ color: '#2c9294', fontWeight: 'bold' }}>
              Bike Model (View Only)
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Manufacturing</InputLabel>
                <Select
                  label="Manufacturing"
                  name="model.manufacturing"
                  value={formData.model?.manufacturing || ''}
                  disabled
                >
                  {Array.from(new Set(bikeModels.map((model) => model.manufacturing))).map(
                    (manufacturing) => (
                      <MenuItem key={manufacturing} value={manufacturing}>
                        {manufacturing}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Model</InputLabel>
                <Select
                  label="Model"
                  name="model.model"
                  value={formData.model?.model || ''}
                  disabled
                >
                  {bikeModels
                    .filter((model) => model.manufacturing === formData.model?.manufacturing)
                    .map((model) => (
                      <MenuItem key={model.model} value={model.model}>
                        {model.model}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Variants</InputLabel>
                <Select
                  label="Variants"
                  name="model.variants"
                  value={formData.model?.variants || ''}
                  disabled
                >
                  {bikeModels
                    .filter(
                      (model) =>
                        model.manufacturing === formData.model?.manufacturing &&
                        model.model === formData.model?.model
                    )
                    .map((model) => (
                      <MenuItem key={model.variants} value={model.variants}>
                        {model.variants}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Color</InputLabel>
                <Select
                  label="Color"
                  name="model.color"
                  value={formData.model?.color || ''}
                  disabled
                >
                  {bikeModels
                    .filter(
                      (model) =>
                        model.manufacturing === formData.model?.manufacturing &&
                        model.model === formData.model?.model &&
                        model.variants === formData.model?.variants
                    )
                    .map((model) => (
                      <MenuItem key={model.color} value={model.color}>
                        {model.color}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Stock Details Section (Editable) */}
            <Grid item xs={12} style={{ color: '#2c9294', fontWeight: 'bold' }}>
              Stock
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Manufacturing</InputLabel>
                <Select
                  label="Manufacturing"
                  name="stockManufacturing"
                  value={stockFormData.stockManufacturing || ''}
                  onChange={handleStockChange}
                >
                  {filteredManufacturing.map((stockManufacturing, index) => (
                    <MenuItem key={index} value={stockManufacturing}>
                      {stockManufacturing}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Model</InputLabel>
                <Select
                  label="Model"
                  name="stockModel"
                  value={stockFormData.stockModel || ''}
                  onChange={handleStockChange}
                  disabled={!stockFormData.stockManufacturing}
                >
                  {filteredStockModels.map((stockModel, index) => (
                    <MenuItem key={index} value={stockModel}>
                      {stockModel}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Variants</InputLabel>
                <Select
                  label="Variants"
                  name="stockVariants"
                  value={stockFormData.stockVariants || ''}
                  onChange={handleStockChange}
                  disabled={!stockFormData.stockModel}
                >
                  {filteredStockVariants.map((variant, index) => (
                    <MenuItem key={index} value={variant}>
                      {variant}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Color</InputLabel>
                <Select
                  label="Color"
                  name="stockColour"
                  value={stockFormData.stockColour || ''}
                  onChange={handleStockChange}
                  disabled={!stockFormData.stockVariants}
                >
                  {filteredStockColours.map((colour, index) => (
                    <MenuItem key={index} value={colour}>
                      {colour}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Engine</InputLabel>
                <Select
                  label="Engine"
                  name="engine"
                  value={stockFormData.engine || ''}
                  onChange={handleStockChange}
                  disabled={!stockFormData.stockColour}
                >
                  {engines.map((engine, index) => (
                    <MenuItem key={index} value={engine}>
                      {engine}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Chassis No</InputLabel>
                <Select
                  label="Chassis No"
                  name="chassisNo"
                  value={stockFormData.chassisNo || ''}
                  onChange={handleStockChange}
                  disabled={!stockFormData.engine}
                >
                  {filteredChassisNumbers.map((chassis, index) => (
                    <MenuItem key={index} value={chassis}>
                      {chassis}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="No Plate"
                name="NoPlate"
                value={stockFormData.NoPlate || ''}
                onChange={handleStockChange}
              />
            </Grid>

          </Grid>

          <Box mt={3}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={saving}
            >
              {saving ? 'Saving...' : 'Save Changes'}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default EditFinanceStockLoan;
