import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    TablePagination,
    TableSortLabel
} from '@mui/material';

const ActivityData = () => {
    const [auditTrails, setAuditTrails] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('customerName');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiURL = process.env.REACT_APP_API_URL;
                const storedToken = localStorage.getItem('token');

                const response = await axios.get(`${apiURL}/scores/all`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${storedToken}`,
                    }
                });

                setAuditTrails(response.data.auditTrails);
            } catch (error) {
                console.error('Error fetching audit trail data:', error);
            }
        };

        fetchData();
    }, []);

    // Handle page change for pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Handle sorting
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // Flatten the audit trail data for rendering in a single table
    const flattenedAuditTrails = auditTrails.flatMap(trail =>
        trail.auditTrail.map(audit => ({
            customerName: trail.customerName,
            attachmentId: trail.attachmentId,
            ...audit
        }))
    );

    // Sort the flattened audit trails based on the selected column and order
    const sortedAuditTrails = flattenedAuditTrails.sort((a, b) => {
        if (orderBy === 'timestamp') {
            return order === 'asc' ? new Date(a[orderBy]) - new Date(b[orderBy]) : new Date(b[orderBy]) - new Date(a[orderBy]);
        } else {
            return order === 'asc' ? (a[orderBy] > b[orderBy] ? 1 : -1) : (a[orderBy] < b[orderBy] ? 1 : -1);
        }
    });

    return (
        <div>
            <Typography variant="h5" style={{ marginLeft: 20 }} gutterBottom>
                Score Logs
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'customerName'}
                                    direction={orderBy === 'customerName' ? order : 'asc'}
                                    onClick={(event) => handleRequestSort(event, 'customerName')}
                                >
                                    Customer Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'attachmentId'}
                                    direction={orderBy === 'attachmentId' ? order : 'asc'}
                                    onClick={(event) => handleRequestSort(event, 'attachmentId')}
                                >
                                    Attachment ID
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'username'}
                                    direction={orderBy === 'username' ? order : 'asc'}
                                    onClick={(event) => handleRequestSort(event, 'username')}
                                >
                                    User Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'action'}
                                    direction={orderBy === 'action' ? order : 'asc'}
                                    onClick={(event) => handleRequestSort(event, 'action')}
                                >
                                    Action
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'timestamp'}
                                    direction={orderBy === 'timestamp' ? order : 'asc'}
                                    onClick={(event) => handleRequestSort(event, 'timestamp')}
                                >
                                    Timestamp
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedAuditTrails
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((audit, idx) => (
                                <TableRow key={idx}>
                                    <TableCell>{audit.customerName}</TableCell>
                                    <TableCell>{audit.attachmentId}</TableCell>
                                    <TableCell>{audit.username}</TableCell>
                                    <TableCell>{audit.action}</TableCell>
                                    <TableCell>{new Date(audit.timestamp).toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={flattenedAuditTrails.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </div>
    );
};

export default ActivityData;
