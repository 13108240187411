import React, { useState, useEffect } from 'react';
import { CircularProgress, Dialog, DialogTitle, DialogContent, Typography, Box, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import axios from 'axios';
import DownloadCustomerDetails from './DownloadCustomerDetails'; // Import the download component

const CustomerDetails = ({ customerId, open, onClose }) => {
    const [customerDetails, setCustomerDetails] = useState(null);
    const [employmentDetails, setEmploymentDetails] = useState([]);
    const [referencesDetails, setReferencesDetails] = useState([]);
    const [financesDetails, setFinancesDetails] = useState([]);
    const [attachmentsDetails, setAttachmentsDetails] = useState([]);
    const [paymentTerms, setPaymentTerms] = useState([]);
    const [scoresDetails, setScoresDetails] = useState([]);
    const [auditTrailDetails, setAuditTrailDetails] = useState([]);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [error, setError] = useState(null);

    const apiURL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (open && customerId) {
            handleOpenCustomerDetails(customerId);
        }
    }, [open, customerId]);

    const handleOpenCustomerDetails = async (customerId) => {
        setLoadingDetails(true);
        try {
            const storedToken = localStorage.getItem('token');

            // Fetch customer details
            const customerResponse = await axios.get(`${apiURL}/customerloans/details/${customerId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${storedToken}`,
                },
            });

            // Fetch employment details
            const employmentResponse = await axios.get(`${apiURL}/employmentloans/details/${customerId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${storedToken}`,
                },
            });

            // Fetch references
            const referencesResponse = await axios.get(`${apiURL}/referencesloans/details/${customerId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${storedToken}`,
                },
            });

            // Fetch finances
            const financesResponse = await axios.get(`${apiURL}/financeloans/details/${customerId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${storedToken}`,
                },
            });

            // Fetch attachments
            const attachmentsResponse = await axios.get(`${apiURL}/attachmentsloans/details/${customerId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${storedToken}`,
                },
            });

            // Fetch payment terms
            const paymentTermsResponse = await axios.get(`${apiURL}/financeloans/payments/details/${customerId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${storedToken}`,
                },
            });

            // Set the data in state
            setCustomerDetails(customerResponse.data);
            setEmploymentDetails(employmentResponse.data);
            setReferencesDetails(referencesResponse.data);
            setFinancesDetails(financesResponse.data);
            setAttachmentsDetails(attachmentsResponse.data);
            setPaymentTerms(paymentTermsResponse.data);

            // Fetch score details and audit trail based on the first attachment ID
            if (attachmentsResponse.data.length > 0) {
                const attachmentId = attachmentsResponse.data[0]._id;
                const scoresResponse = await axios.get(`${apiURL}/scores/details/${attachmentId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${storedToken}`,
                    },
                });
                setScoresDetails(scoresResponse.data.scores);
                setAuditTrailDetails(scoresResponse.data.auditTrail);
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoadingDetails(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>Customer Details</DialogTitle>
            <DialogContent dividers>
                {loadingDetails && <CircularProgress />}
                {error && <Typography color="error">Error: {error}</Typography>}
                {!loadingDetails && !error && (
                    <Box>
                        {/* Download Button */}
                        <DownloadCustomerDetails
                            customerDetails={customerDetails}
                            employmentDetails={employmentDetails}
                            referencesDetails={referencesDetails}
                            financesDetails={financesDetails}
                            attachmentsDetails={attachmentsDetails}
                            paymentTerms={paymentTerms}
                            scoresDetails={scoresDetails}
                            auditTrailDetails={auditTrailDetails}
                        />

                        {/* Customer Loan Details */}
                        {customerDetails && (
                            <div style={{ marginBottom: '20px' }}>
                                <Typography variant="h6">Customer Loan Details</Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableBody>
                                            <TableRow><TableCell>Id</TableCell><TableCell>{customerDetails._id}</TableCell></TableRow>
                                            <TableRow><TableCell>Name</TableCell><TableCell>{customerDetails.name}</TableCell></TableRow>
                                            <TableRow><TableCell>NRIC No</TableCell><TableCell>{customerDetails.nricNo}</TableCell></TableRow>
                                            <TableRow><TableCell>Age</TableCell><TableCell>{customerDetails.age}</TableCell></TableRow>
                                            <TableRow><TableCell>Number Plate </TableCell><TableCell>{customerDetails.NoPlate}</TableCell></TableRow>
                                            <TableRow><TableCell>Mobile Number</TableCell><TableCell>{customerDetails.mobileNumber}</TableCell></TableRow>
                                            <TableRow><TableCell>Email</TableCell><TableCell>{customerDetails.email}</TableCell></TableRow>
                                            <TableRow><TableCell>Best Time to Call</TableCell><TableCell>{customerDetails.bestTimeToCall}</TableCell></TableRow>
                                            <TableRow><TableCell>Gender</TableCell><TableCell>{customerDetails.gender}</TableCell></TableRow>
                                            <TableRow><TableCell>Marital Status</TableCell><TableCell>{customerDetails.maritalStatus}</TableCell></TableRow>
                                            <TableRow><TableCell>Race</TableCell><TableCell>{customerDetails.race}</TableCell></TableRow>
                                            <TableRow><TableCell>Address</TableCell><TableCell>{customerDetails.address}</TableCell></TableRow>
                                            <TableRow><TableCell>City</TableCell><TableCell>{customerDetails.city}</TableCell></TableRow>
                                            <TableRow><TableCell>Postcode</TableCell><TableCell>{customerDetails.postcode}</TableCell></TableRow>
                                            <TableRow><TableCell>State</TableCell><TableCell>{customerDetails.state}</TableCell></TableRow>
                                            <TableRow><TableCell>Status</TableCell><TableCell>{customerDetails.status}</TableCell></TableRow>
                                            <TableRow><TableCell>createdByUsername</TableCell><TableCell>{customerDetails.createdByUsername}</TableCell></TableRow>
                                            <TableRow><TableCell>Created At</TableCell><TableCell>{new Date(customerDetails.createdAt).toLocaleDateString()}</TableCell></TableRow>
                                            <TableRow><TableCell>Updated At</TableCell><TableCell>{new Date(customerDetails.updatedAt).toLocaleDateString()}</TableCell></TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}

                        {/* Employment Details */}
                        {employmentDetails.length > 0 && (
                            <div style={{ marginBottom: '20px' }}>
                                <Typography variant="h6">Employment Details</Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableBody>
                                            {employmentDetails.map((employment, index) => (
                                                <React.Fragment key={index}>
                                                    <TableRow><TableCell>Occupation</TableCell><TableCell>{employment.occupation}</TableCell></TableRow>
                                                    <TableRow><TableCell>Occupation Type</TableCell><TableCell>{employment.occupationType}</TableCell></TableRow>
                                                    <TableRow><TableCell>Employment Length</TableCell><TableCell>{employment.employmentLength}</TableCell></TableRow>
                                                    <TableRow><TableCell>Employer Name</TableCell><TableCell>{employment.employerName}</TableCell></TableRow>
                                                    <TableRow><TableCell>Employer Phone</TableCell><TableCell>{employment.employerPhoneNumber}</TableCell></TableRow>
                                                    <TableRow><TableCell>City</TableCell><TableCell>{employment.city}</TableCell></TableRow>
                                                    <TableRow><TableCell>Postcode</TableCell><TableCell>{employment.postcode}</TableCell></TableRow>
                                                    <TableRow><TableCell>State</TableCell><TableCell>{employment.state}</TableCell></TableRow>
                                                    <TableRow><TableCell>Monthly Gross Salary</TableCell><TableCell>{employment.monthlyGrossSalary}</TableCell></TableRow>
                                                    <TableRow><TableCell>Net Salary</TableCell><TableCell>{employment.monthlyNetSalary}</TableCell></TableRow>
                                                </React.Fragment>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}

                        {/* References Details */}
                        {referencesDetails.length > 0 && (
                            <div style={{ marginBottom: '20px' }}>
                                <Typography variant="h6">References</Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableBody>
                                            {referencesDetails.map((reference, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>Reference Name</TableCell>
                                                    <TableCell>{reference.name}</TableCell>
                                                    <TableCell>Relationship</TableCell>
                                                    <TableCell>{reference.relationship}</TableCell>
                                                    <TableCell>Contact Number</TableCell>
                                                    <TableCell>{reference.contactNumber}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}

                        {/* Finances Details */}
                        {financesDetails.length > 0 && (
                            <div style={{ marginBottom: '20px' }}>
                                <Typography variant="h6">Finances</Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableBody>
                                            {financesDetails.map((finance, index) => (
                                                <React.Fragment key={index}>
                                                    <TableRow><TableCell>Product</TableCell><TableCell>{finance.product}</TableCell></TableRow>
                                                    <TableRow><TableCell>Commitment</TableCell><TableCell>{finance.customerCommitment}</TableCell></TableRow>
                                                    <TableRow><TableCell>Condition</TableCell><TableCell>{finance.condition}</TableCell></TableRow>
                                                    <TableRow ><TableCell>Bike Manufacturing </TableCell><TableCell>{finance.model.manufacturing}</TableCell></TableRow>
                                                    <TableRow><TableCell>Bike Model </TableCell><TableCell>{finance.model.model}</TableCell></TableRow>
                                                    <TableRow><TableCell>Bike Variants </TableCell><TableCell>{finance.model.variants}</TableCell></TableRow>
                                                    <TableRow><TableCell>Bike Colour </TableCell><TableCell>{finance.model.color}</TableCell></TableRow>
                                                    <TableRow><TableCell>Stock Manufacturing</TableCell><TableCell>{finance.stockManufacturing}</TableCell></TableRow>
                                                    <TableRow><TableCell>Stock Model</TableCell><TableCell>{finance.stockModel}</TableCell></TableRow>
                                                    <TableRow><TableCell>Stock Variants</TableCell><TableCell>{finance.stockVariants}</TableCell></TableRow>
                                                <TableRow><TableCell>Stock Colour</TableCell><TableCell>{finance.stockColour}</TableCell></TableRow>
                                                    <TableRow><TableCell>Price</TableCell><TableCell>{finance.salePrice}</TableCell></TableRow>
                                                    <TableRow><TableCell>Deposit</TableCell><TableCell>{finance.deposit}</TableCell></TableRow>
                                                    <TableRow><TableCell>Tenure</TableCell><TableCell>{finance.tenure}</TableCell></TableRow>
                                                    <TableRow><TableCell>Financing Amount</TableCell><TableCell>{finance.financingAmount}</TableCell></TableRow>
                                                </React.Fragment>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}

                        {/* Scores Details */}
                        {scoresDetails.length > 0 && (
                            <div style={{ marginBottom: '20px' }}>
                                <Typography variant="h6">Scores</Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableBody>
                                            {scoresDetails.map((score, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{score.title}</TableCell>
                                                    <TableCell>{score.score} / {score.scoreOutOf}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}

                        {/* Audit Trail Details */}
                        {auditTrailDetails.length > 0 && (
                            <div style={{ marginBottom: '20px' }}>
                                {auditTrailDetails.map((audit, index) => (
                                    <Box key={index} display="flex" flexDirection="row" justifyContent="space-between" style={{ marginBottom: '10px' }}>
                                        {/* Left side: Scored By */}
                                        <Box display="flex" flexDirection="column" style={{ flex: 1 }}>
                                            <Typography variant="body1" fontWeight="bold">Scored By:</Typography>
                                            <Typography variant="body1">{audit.username}</Typography>
                                        </Box>

                                        {/* Right side: Created By */}
                                        <Box display="flex" flexDirection="column" style={{ flex: 1 }}>
                                            <Typography variant="body1" fontWeight="bold">Created By:</Typography>
                                            <Typography variant="body1">{customerDetails.createdByUsername}</Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </div>
                        )}

                        {/* Attachments Details */}
                        {attachmentsDetails.length > 0 && (
                            <div style={{ marginBottom: '20px' }}>
                                <Typography variant="h6">Attachments</Typography>
                                {attachmentsDetails.map((attachment, index) => (
                                    <div key={index}>
                                        <p>
                                            NRIC Front:{' '}
                                            <a href={attachment.nric.front} target="_blank" rel="noopener noreferrer">
                                                View NRIC Front
                                            </a>
                                        </p>
                                        <p>
                                            NRIC Back:{' '}
                                            <a href={attachment.nric.back} target="_blank" rel="noopener noreferrer">
                                                View NRIC Back
                                            </a>
                                        </p>
                                        <p>
                                            License Front:{' '}
                                            <a href={attachment.licence.front} target="_blank" rel="noopener noreferrer">
                                                View License Front
                                            </a>
                                        </p>
                                        <p>
                                            License Back:{' '}
                                            <a href={attachment.licence.back} target="_blank" rel="noopener noreferrer">
                                                View License Back
                                            </a>
                                        </p>
                                        <p>
                                            Income Statement:{' '}
                                            <a href={attachment.incomeStatement.path} target="_blank" rel="noopener noreferrer">
                                                View Income Statement
                                            </a>
                                        </p>
                                        <p>
                                            Salary Slip:{' '}
                                            <a href={attachment.salarySlip.path} target="_blank" rel="noopener noreferrer">
                                                View Salary Slip
                                            </a>
                                        </p>
                                        <p>
                                            EPF Statement:{' '}
                                            <a href={attachment.epfStatement.path} target="_blank" rel="noopener noreferrer">
                                                View EPF Statement
                                            </a>
                                        </p>
                                        <p>
                                            Utility Bill:{' '}
                                            <a href={attachment.utilitiesBill.path} target="_blank" rel="noopener noreferrer">
                                                View Utility Bill
                                            </a>
                                        </p>
                                        <p>
                                            CTOS Report:{' '}
                                            <a href={attachment.ctosReport.path} target="_blank" rel="noopener noreferrer">
                                                View CTOS Report
                                            </a>
                                        </p>
                                    </div>
                                ))}
                            </div>
                        )}

                        {/* Payment Terms Details */}
                        {paymentTerms.length > 0 && (
                            <div style={{ marginBottom: '20px' }}>
                                <Typography variant="h6">Payment Terms</Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableBody>
                                            {paymentTerms.map((term, index) => (
                                                <React.Fragment key={index}>
                                                    <TableRow><TableCell>Customer Name</TableCell><TableCell>{term.customerName}</TableCell></TableRow>
                                                    <TableRow><TableCell>NIC</TableCell><TableCell>{term.NIC}</TableCell></TableRow>
                                                    <TableRow><TableCell>Status</TableCell><TableCell>{term.status}</TableCell></TableRow>
                                                    <TableRow><TableCell>Financing Amount</TableCell><TableCell>{term.financingAmount}</TableCell></TableRow>
                                                    <TableRow><TableCell>Tenure</TableCell><TableCell>{term.tenure}</TableCell></TableRow>
                                                    <TableRow><TableCell>Paid Installments</TableCell><TableCell>{term.paidInstallment}</TableCell></TableRow>
                                                    <TableRow><TableCell>Unpaid Installments</TableCell><TableCell>{term.unpaidInstallment}</TableCell></TableRow>
                                                    <TableRow><TableCell>Overdue Installments</TableCell><TableCell>{term.overdueInstallment}</TableCell></TableRow>
                                                    <TableRow><TableCell>Monthly Amount</TableCell><TableCell>{term.monthlyAmount}</TableCell></TableRow>

                                                    <TableRow>
                                                        <TableCell colSpan={2}>
                                                            <Typography variant="subtitle1">Payment Dates</Typography>
                                                            <TableContainer component={Paper}>
                                                                <Table>
                                                                    <TableBody>
                                                                        {term.paymentDates.map((paymentDate, index) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell>Due Date</TableCell>
                                                                                <TableCell>{new Date(paymentDate.dueDate).toLocaleDateString()}</TableCell>
                                                                                <TableCell>Status</TableCell>
                                                                                <TableCell>{paymentDate.status}</TableCell>
                                                                                <TableCell>Paid Date</TableCell>
                                                                                <TableCell>{paymentDate.paidDate ? new Date(paymentDate.paidDate).toLocaleDateString() : 'Not Paid'}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default CustomerDetails;
