import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField // Import the TextField component
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { formatDate } from '../../utils/formatDate'; // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom';

const BikeModelData = () => {
  const [bikeModels, setBikeModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedModelId, setSelectedModelId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // Add state for search term
  const navigate = useNavigate();
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchBikeModels = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/bikemodels`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          }
        });
        setBikeModels(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBikeModels();
  }, [apiURL]);

  const handleMenuOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedModelId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedModelId(null);
  };

  const handleEdit = () => {
    if (selectedModelId) {
      navigate(`/bikemodels/${selectedModelId}`);
    }
    handleMenuClose();
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDelete = async () => {
    if (selectedModelId) {
      try {
        const storedToken = localStorage.getItem('token');
        await axios.delete(`${apiURL}/bikemodels/${selectedModelId}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setBikeModels((prevBikeModels) =>
          prevBikeModels.filter((model) => model._id !== selectedModelId)
        );
      } catch (err) {
        console.error('Error deleting bike model:', err);
      } finally {
        handleMenuClose();
        handleCloseDialog();
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter bike models based on the search term
  const filteredBikeModels = bikeModels.filter((model) => {
    const manufacturing = model.manufacturing?.toLowerCase() || '';
    const bikeModel = model.model?.toLowerCase() || '';
    const variants = model.variants?.toLowerCase() || '';
    const price = model.price? model.price.toString() : '';

    return (
      manufacturing.includes(searchTerm.toLowerCase()) ||
      bikeModel.includes(searchTerm.toLowerCase()) ||
      variants.includes(searchTerm.toLowerCase()) ||
      price.includes(searchTerm)
    );
  });

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="10px"
        marginTop="10px"
      >
        <TextField
          label="Search by Manufacturing, Model,  Variants, or Price"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Update the search term on change
          style={{ marginLeft: '10px', width: '300px' }} // Adjust styling as necessary
        />
        <Button
          variant="contained"
          color="primary"
          href="/bikemodels/AddBikeModel"
          style={{ backgroundColor: '#2c9294', marginRight: '10px' }}
        >
          Add Bike Model
        </Button>
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Manufacturing</TableCell>
            <TableCell>Model</TableCell>
            <TableCell>Variants</TableCell>
            <TableCell>Color</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredBikeModels.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((model) => (
            <TableRow key={model._id}>
              <TableCell>{model._id}</TableCell>
              <TableCell>{model.manufacturing}</TableCell>
              <TableCell>{model.model}</TableCell>
              <TableCell>{model.variants}</TableCell>
              <TableCell>{model.color}</TableCell>
              <TableCell>{model.price}</TableCell>
              <TableCell>{formatDate(model.createdAt)}</TableCell>
              <TableCell>{formatDate(model.updatedAt)}</TableCell>
              <TableCell>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={(event) => handleMenuOpen(event, model._id)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl) && selectedModelId === model._id}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleEdit}>Edit</MenuItem>
                  <MenuItem onClick={handleOpenDialog}>Delete</MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={filteredBikeModels.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this bike model? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BikeModelData;
