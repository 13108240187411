import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip 
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CustomerDetails from './CustomerDetails'; // Import CustomerDetails component

const PaymentermsData = () => {
  const [financeLoans, setFinanceLoans] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [newTerm, setNewTerm] = useState({ Tenure: '' });
  const [selectedLoanId, setSelectedLoanId] = useState(null);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null); // State for customer ID
  const [openCustomerDialog, setOpenCustomerDialog] = useState(false); // State to control customer details dialog
  const [searchTerm, setSearchTerm] = useState(''); // Search term state
  const apiURL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFinanceLoans = async () => {
      setLoading(true);
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/financeloans/payments`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setFinanceLoans(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFinanceLoans();
  }, [apiURL]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event, loanId) => {
    setAnchorEl(event.currentTarget);
    setSelectedLoanId(loanId);
  };

  const handleEditClick = () => {
    if (selectedLoanId) {
      navigate(`/paymenterms/${selectedLoanId}`);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenPopup = async () => {
    console.log("Selected Loan ID:", selectedLoanId);
    try {
      const storedToken = localStorage.getItem('token');
      const response = await axios.get(`${apiURL}/financeloans/${selectedLoanId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      });
      setSelectedLoan(response.data);
      setNewTerm({ Tenure: response.data.tenure });
    } catch (err) {
      setError(err.message);
    }
    setOpen(true);
    handleClose();
  };

  const handleClosePopup = () => {
    setOpen(false);
  };

  const handleOpenViewDialog = async () => {
    console.log("Selected Loan ID:", selectedLoanId);
    setLoading(true);
    try {
      if (!selectedLoanId) {
        throw new Error('No loan selected');
      }
      const storedToken = localStorage.getItem('token');
      const response = await axios.get(`${apiURL}/paymenterms/${selectedLoanId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      });
      if (response.data.length === 0) {
        throw new Error('No payment terms found for this Finance Loan ID');
      }
      setPaymentTerms(Array.isArray(response.data) ? response.data : []);
      setLoading(false);
      setOpenViewDialog(true);
    } catch (err) {
      setError(err.message);
      setPaymentTerms([]);
      setLoading(false);
    }
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    setPaymentTerms([]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewTerm({ ...newTerm, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const storedToken = localStorage.getItem('token');
      const termWithLoanId = {
        financeLoanId: selectedLoanId,
        ...newTerm,
      };
      console.log("Submitting Payment Term with Data:", termWithLoanId);
      await axios.post(`${apiURL}/paymenterms`, termWithLoanId, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      });
      handleClosePopup();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // When clicking on customer name, open the CustomerDetails dialog
  const handleCustomerNameClick = (customerId) => {
    setSelectedCustomerId(customerId); // Set the selected customer ID
    setOpenCustomerDialog(true); // Open the customer dialog
  };

  const filteredLoans = financeLoans.filter((loan) => {
    const customerName = loan.customerName ? loan.customerName.toLowerCase() : '';
    const NIC = loan.NIC ? loan.NIC.toLowerCase() : '';
    const noPlate = loan.noPlate ? loan.noPlate.toLowerCase() : '';

    return (
      customerName.includes(searchTerm.toLowerCase()) ||
      NIC.includes(searchTerm.toLowerCase()) ||
      noPlate.includes(searchTerm.toLowerCase())
    );
  });

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: '20px', marginLeft: '20px' }}>
        <TextField
          margin="dense"
          label="Search by Customer Name, NRIC or No Plate"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: '300px' }}
        />
        <Typography variant="h5">Payment Terms</Typography>
      </div>

      {loading && <CircularProgress />}
      {error && <Typography color="error">Error: {error}</Typography>}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Customer Name</TableCell>
            <TableCell>NRIC</TableCell>
            <TableCell>No Plate</TableCell>
            <TableCell>Financing Amount</TableCell>
            <TableCell>Tenure</TableCell>
            <TableCell>Paid Installments</TableCell>
            <TableCell>Paid Amount</TableCell>
            <TableCell>Unpaid Installments</TableCell>
            <TableCell>Unpaid Amount</TableCell>
            <TableCell>Overdue Installments</TableCell>
            <TableCell>Monthly Amount</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredLoans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((loan) => (
            <TableRow key={loan.financeLoanId}>
              <TableCell>
                <Tooltip title="Customer Details">
                  <Button onClick={() => handleCustomerNameClick(loan.customerId)}>
                    {loan.customerName}
                  </Button>
                </Tooltip>
              </TableCell>
              <TableCell>{loan.NIC}</TableCell>
              <TableCell>{loan.noPlate}</TableCell>
              <TableCell>{loan.financingAmount.toFixed(2)}</TableCell>
              <TableCell>{loan.tenure}</TableCell>
              <TableCell>{loan.paidInstallment}</TableCell>
              <TableCell>{loan.paidInstallmentAmount.toFixed(2)}</TableCell>
              <TableCell>{loan.unpaidInstallment}</TableCell>
              <TableCell>{loan.unpaidInstallmentAmount.toFixed(2)}</TableCell>
              <TableCell>{loan.overdueInstallment}</TableCell>
              <TableCell>{loan.monthlyAmount.toFixed(2)}</TableCell>
              <TableCell>
                <IconButton onClick={(event) => handleClick(event, loan.financeLoanId)}>
                  <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                  <MenuItem onClick={handleOpenPopup}>Add Payment Terms</MenuItem>
                  <MenuItem onClick={handleEditClick}>View Payment Terms</MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={financeLoans.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={open} onClose={handleClosePopup}>
        <DialogTitle>Add Payment Terms</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="Tenure"
            label="Tenure"
            type="number"
            fullWidth
            value={newTerm.Tenure}
            onChange={handleChange}
            disabled
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup}>Cancel</Button>
          <Button onClick={handleSubmit} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Add Payment Terms'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openViewDialog} onClose={handleCloseViewDialog}>
        <DialogTitle>Payment Terms Details</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tenure</TableCell>
                  <TableCell>Monthly Amount</TableCell>
                  <TableCell>Overdue Installments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentTerms.map((term) => (
                  <TableRow key={term.financeLoanId}>
                    <TableCell>{term.Tenure}</TableCell>
                    <TableCell>{term.MonthlyAmount.toFixed(2)}</TableCell>
                    <TableCell>{term.OverdueInstallments}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseViewDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Add CustomerDetails dialog */}
      <CustomerDetails
        customerId={selectedCustomerId}
        open={openCustomerDialog}
        onClose={() => setOpenCustomerDialog(false)}
      />
    </div>
  );
};

export default PaymentermsData;
