import React, { useEffect, useState } from 'react';
import { TableContainer, Paper, TablePagination, TextField, Box, Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import AddReferenceDialog from '../AddReferenceDialog'; // Adjust the path as per your file structure

const ReferenceLoan = () => {
  const [references, setReferences] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [newReference, setNewReference] = useState({
    customerId: '', // Adjust according to your data structure
    name: '',
    nricNo: '',
    contactNumber: '',
    relationship: ''
  });
  const [searchTerm, setSearchTerm] = useState(''); // Add searchTerm state

  const apiURL = process.env.REACT_APP_API_URL;

  const fetchReferences = async () => {
    try {
      const storedToken = localStorage.getItem('token');
      const response = await axios.get(`${apiURL}/referencesloans`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        }
      });
      const transformedReferences = response.data.map(item => ({
        ...item,
        customerName: item.customerId ? item.customerId.name : 'Unknown',
      }));
      setReferences(transformedReferences);
    } catch (error) {
      console.error('Error fetching references:', error);
    }
  };

  useEffect(() => {
    fetchReferences();
  }, [apiURL]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReference({ ...newReference, [name]: value });
  };

  const handleFormSubmit = async () => {
    try {
      const storedToken = localStorage.getItem('token');
      await axios.post(`${apiURL}/referencesloans`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        }
      }, newReference);
      setOpenDialog(false);
      fetchReferences(); // Fetch references again after adding new reference
    } catch (error) {
      console.error('Error creating reference:', error);
    }
  };

  const referenceColumns = [
    { field: 'customerName', headerName: 'Customer' },
    { field: 'name', headerName: 'Name' },
    { field: 'nricNo', headerName: 'NRIC No' },
    { field: 'contactNumber', headerName: 'Contact Number' },
    { field: 'relationship', headerName: 'Relationship' },
  ];

  // Filter references based on search term (customerId.name)
  const filteredReferences = references.filter(reference =>
    reference.customerName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Box display="flex" justifyContent="space-between" padding="5px">
        <TextField
          label="Search by Customer Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
          style={{ width: '300px' }}
        />
      </Box>

      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {referenceColumns.map((col) => (
                <TableCell key={col.field}>{col.headerName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredReferences.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((reference) => (
              <TableRow key={reference._id}>
                {referenceColumns.map((col) => (
                  <TableCell key={col.field}>
                    {col.render ? col.render(reference[col.field]) : reference[col.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 40]}
          component="div"
          count={filteredReferences.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <AddReferenceDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onChange={handleInputChange}
        onSubmit={handleFormSubmit}
        newReference={newReference}
      />
    </div>
  );
};

export default ReferenceLoan;
