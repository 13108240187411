import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  TextField, Button, Grid, Typography, Container, Paper, Box, MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import {jwtDecode} from 'jwt-decode'; // Corrected import

// Define role-based available statuses
const roleBasedStatusOptions = {
  'manager': [
    'pending', 'approved', 'cancelled', '1001', '3001', '3002', '3003', '3004', '3005', '3006', 
    '3017', '3027', '3037', '3047', '3057', '4001', '4002', '5001', '6001', '6002', '6003', 
    '6004', '7001', '8001'
  ],
  // 'admin': ['pending', 'approved', 'cancelled', 'completed'],
  'account': ['issue'], // Account role can only set status to "issue"
  'sales': ['draft', 'ready', 'claim'],
  'credit_check': ['draft', 'pending']
};

const EditCustomerLoan = () => {
  const { customerloandId } = useParams();
  const navigate = useNavigate();
  const [loan, setLoan] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    nricNo: '',
    mobileNumber: '',
    email: '',
    NoPlate: '',
    engine: '',
    chassisNo: '',
    gender: '',
    maritalStatus: '',
    race: '',
    address: '',
    status: ''
  });
  const [statusOptions, setStatusOptions] = useState([]);
  const [user, setUser] = useState({ username: '', role: '' }); // Hold user data
  const apiURL = process.env.REACT_APP_API_URL;

  // Fetch the role and username of the logged-in user
  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const storedToken = localStorage.getItem('token');

        const response = await fetch(`${apiURL}/users/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        // Capitalize the first letter of the role
        const roleName = data.role.name.charAt(0).toUpperCase() + data.role.name.slice(1);

        setUser({ username: data.username, role: roleName }); // Set the username and the capitalized role
        setStatusOptions(roleBasedStatusOptions[roleName.toLowerCase()] || []); // Set status options based on role
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      const decodedToken = jwtDecode(storedToken);
      if (decodedToken && decodedToken.id) {
        fetchUserData(decodedToken.id); // Fetch user data using the user ID from the token
      }
    }
  }, [apiURL]);

  // Fetch loan details and set form data
  useEffect(() => {
    const fetchLoan = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/customerloans/${customerloandId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          }
        });
        const { _id, __v, createdAt, updatedAt, ...loanData } = response.data;
        setLoan(response.data);
        setFormData(loanData);
      } catch (error) {
        console.error('Error fetching loan:', error);
      }
    };

    fetchLoan();
  }, [customerloandId, apiURL]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const storedToken = localStorage.getItem('token');
      await axios.put(`${apiURL}/customerloans/${customerloandId}`,
        formData, 
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
        }
    });
      navigate('/customerloans');
    } catch (error) {
      console.error('Error updating loan:', error);
    }
  };

  // Determine next status based on current status and role
  const getNextStatusOptions = () => {
    const currentStatus = formData.status;
    const availableStatuses = statusOptions;
    
    // Filter out statuses that the user role is not allowed to update to
    return availableStatuses.filter(status => status !== currentStatus);
  };

  return (
    <Container component={Paper} elevation={3} sx={{ padding: 3, maxWidth: 'md', marginTop: 5 }}>
      <Typography variant="h4" gutterBottom>
        Edit Loan
      </Typography>
      {loan ? (
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            {/* Display Current Status */}
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Current Status:</strong> {formData.status || 'Loading...'}
              </Typography>
            </Grid>

            {/* Dropdown for Next Status */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Next Status</InputLabel>
                <Select
                  label="Next Status"
                  name="status"
                  value={formData.status || ''}  // The status field in formData
                  onChange={handleInputChange}
                >
                  {statusOptions.length === 0 && (
                    <MenuItem disabled value="">
                      No status available
                    </MenuItem>
                  )}
                  {getNextStatusOptions().map((status, index) => (
                    <MenuItem key={index} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Other form fields */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                name="name"
                value={formData.name || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="NRIC No"
                name="nricNo"
                value={formData.nricNo || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Mobile Number"
                name="mobileNumber"
                value={formData.mobileNumber || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="No Plate"
                name="NoPlate"
                value={formData.NoPlate || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                name="email"
                value={formData.email || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Gender"
                name="gender"
                value={formData.gender || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Marital Status"
                name="maritalStatus"
                value={formData.maritalStatus || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Race"
                name="race"
                value={formData.race || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Address"
                name="address"
                value={formData.address || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>

          </Grid>
          <Box sx={{ marginTop: 2 }}>
            <Button type="submit" variant="contained" color="primary">
              Update
            </Button>
          </Box>
        </form>
      ) : (
        <Typography variant="h6">Loading loan details...</Typography>
      )}
    </Container>
  );
};

export default EditCustomerLoan;
