import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import StorageIcon from '@mui/icons-material/Storage';
import PaymentIcon from '@mui/icons-material/Payment';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import PersonIcon from '@mui/icons-material/Person';
import InventoryIcon from '@mui/icons-material/Inventory';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import {jwtDecode} from 'jwt-decode';  // Corrected import
import TuneIcon from '@mui/icons-material/Tune';
import './sidebar.scss';

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const getActiveClass = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  // Retrieve and decode token
  const token = localStorage.getItem('token');
  let userRole = '';

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      console.log('Decoded Token:', decodedToken);  // Debugging to check structure

      userRole = decodedToken.user?.role || decodedToken.role;  // Adjust depending on token structure
    } catch (error) {
      console.error('Token decoding failed:', error);
    }
  }

  console.log('User Role:', userRole);  // Check what role is being set

  // Define roles that can access each item
  const roleAccess = {
    '/': [ 'manager','superadmin'],
    '/users': ['manager','superadmin'],
     '/profile': ['superadmin', 'manager','admin','account','sales','credit_check'],
    '/forecast': ['superadmin','manager','account'],
    '/customerloans': ['superadmin', 'manager','admin','account','credit_check'],
    '/customers/cashs/': ['superadmin', 'manager','admin','account'],
    '/salesloans': ['sales'],
    '/salescash': ['sales'],
    '/paymenterms': ['superadmin','manager','admin','account'],
    '/bikemodels': ['superadmin','manager','admin'],
    '/stocks': ['superadmin','admin','manager'],
    '/activities': ['superadmin','manager'],
    '/roles': ['superadmin'],
  };

  // Determine if the user has access to a given route
  const hasAccess = (path) => {
    const allowedRoles = roleAccess[path];
    console.log(`Checking access for path ${path} with role ${userRole}`);  // Debugging
    return allowedRoles ? allowedRoles.includes(userRole) : true;
  };

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: 'none' }}>
          <span className="logo">
            MMS 
          </span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
        {hasAccess('/users') && (

          <li className={getActiveClass('/')}>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <DashboardIcon className="icon" />
              <span>Dashboard </span>
            </Link>
          </li>
          )}
          <li className={getActiveClass('/profile')}>
            <Link to="/profile" style={{ textDecoration: 'none' }}>
              <PersonIcon className="icon" />
              <span>User Profile </span>
            </Link>
          </li>
          {hasAccess('/forecast') && (
          <li className={getActiveClass('/forecast')}>
            <Link to="/forecast" style={{ textDecoration: 'none' }}>
              <AutoGraphIcon className="icon" />
              <span>Forecast </span>
            </Link>
          </li>
           )}
    
          {hasAccess('/users') && (
            <li className={getActiveClass('/users')}>
              <Link to="/users" style={{ textDecoration: 'none' }}>
                <PeopleAltIcon className="icon" />
                <span>Users </span>
              </Link>
            </li>
          )}
          {hasAccess('/customerloans') && (
            <li className={getActiveClass('/customerloans')}>
              <Link to="/customerloans" style={{ textDecoration: 'none' }}>
                <ListAltIcon className="icon" />
                <span>Loans Customer</span>
              </Link>
            </li>
          )}
          {hasAccess('/customers/cashs/') && (
            <li className={getActiveClass('/customers/cashs/')}>
              <Link to="/customers/cashs/" style={{ textDecoration: 'none' }}>
                <StorageIcon className="icon" />
                <span>Cash Customer </span>
              </Link>
            </li>
          )}
          {hasAccess('/salesloans') && (
            <li className={getActiveClass('/salesloans')}>
              <Link to="/salesloans" style={{ textDecoration: 'none' }}>
                <CreditScoreIcon className="icon" />
                <span>Loan Customer Loan </span>
              </Link>
            </li>
          )}
          {hasAccess('/salescash') && (
            <li className={getActiveClass('/salescash')}>
              <Link to="/salescash" style={{ textDecoration: 'none' }}>
                <MonetizationOnIcon className="icon" />
                <span>Cash Customer Cash </span>
              </Link>
            </li>
          )}
          {hasAccess('/paymenterms') && (
            <li className={getActiveClass('/paymenterms')}>
              <Link to="/paymenterms" style={{ textDecoration: 'none' }}>
                <PaymentIcon className="icon" />
                <span>Payment Terms </span>
              </Link>
            </li>
          )}
          {hasAccess('/bikemodels') && (
            <li className={getActiveClass('/bikemodels')}>
              <Link to="/bikemodels" style={{ textDecoration: 'none' }}>
                <TwoWheelerIcon className="icon" />
                <span>Bike Model </span>
              </Link>
            </li>
          )}
          {hasAccess('/stocks') && (
            <li className={getActiveClass('/stocks')}>
              <Link to="/stocks" style={{ textDecoration: 'none' }}>
                <InventoryIcon className="icon" />
                <span>Stocks </span>
              </Link>
            </li>
          )}
          {hasAccess('/roles') && (
            <li className={getActiveClass('/roles')}>
              <Link to="/roles" style={{ textDecoration: 'none' }}>
                <TuneIcon  className="icon" />
                <span>Role </span>
              </Link>
            </li>
          )}
          {hasAccess('/activities') && (
            <li className={getActiveClass('/activities')}>
              <Link to="/activities" style={{ textDecoration: 'none' }}>
                <ChangeHistoryIcon className="icon" />
                <span>Activities  Logs</span>
              </Link>
            </li>
          )}
          <li onClick={handleLogout} className={getActiveClass('/login')}>
            <LogoutIcon className="icon" />
            <Link to="/login" style={{ textDecoration: 'none' }}>
              <span>Logout </span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
