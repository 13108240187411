import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, MenuItem, Button, Typography, Container, Grid } from '@mui/material';
import {jwtDecode} from 'jwt-decode';
import './addUser.scss';

const AddUser = () => {
    const apiURL = process.env.REACT_APP_API_URL;

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        role: '',  // Role will now store role ID
        outletId: '',
    });

    const [roles, setRoles] = useState([]); // Fetch roles with their IDs
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            try {
                const decodedToken = jwtDecode(storedToken);
                const userId = decodedToken.id;

                if (userId) {
                    // Fetch user data to get outletId
                    axios.get(`${apiURL}/users/${userId}`, {
                        headers: {
                            'Authorization': `Bearer ${storedToken}`,
                        },
                    })
                    .then(response => {
                        const userData = response.data;
                        if (userData.outletId && userData.outletId._id) {
                            setFormData((prevData) => ({
                                ...prevData,
                                outletId: userData.outletId._id,  // Still keep outletId in formData
                            }));
                        } else {
                            setError('Failed to retrieve Outlet ID from user data.');
                        }
                    })
                    .catch(err => {
                        setError('Failed to fetch user data.');
                    });

                    // Fetch roles from the API
                    axios.get(`${apiURL}/auth/role`, {
                        headers: {
                            'Authorization': `Bearer ${storedToken}`,
                        },
                    })
                    .then(response => {
                        setRoles(response.data); // Set roles with IDs
                    })
                    .catch(err => {
                        setError('Failed to fetch roles.');
                    });
                } else {
                    setError('User ID is missing in the token.');
                }
            } catch (error) {
                setError('Failed to decode token. Please log in again.');
            }
        } else {
            setError('No token found. Please log in again.');
        }
    }, [apiURL]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle role change to store the role ID instead of the name
    const handleRoleChange = (e) => {
        const roleId = e.target.value;
        setFormData((prevData) => ({
            ...prevData,
            role: roleId, // Store role ID instead of name
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.outletId) {
            setError('Outlet ID is missing. Please ensure you are logged in.');
            return;
        }
        try {
            const storedToken = localStorage.getItem('token');
            await axios.post(`${apiURL}/auth/register`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            setSuccess('User added successfully!');
            setError(null);
            setFormData({
                username: '',
                email: '',
                password: '',
                role: '',  // Reset role after submission
                outletId: formData.outletId,  // Keep the outletId in formData
            });
        } catch (err) {
            setError('Failed to add user. Please try again.');
            setSuccess(null);
        }
    };

    return (
        <Container maxWidth="md" className="addUser">
            <Typography variant="h4" gutterBottom>
                Add User
            </Typography>
            <form onSubmit={handleSubmit} className="addUserForm">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Username"
                            variant="outlined"
                            fullWidth
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            type="email"
                            variant="outlined"
                            fullWidth
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Password"
                            type="password"
                            variant="outlined"
                            fullWidth
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            select
                            label="Role"
                            variant="outlined"
                            fullWidth
                            name="role"
                            value={formData.role} // This will store the role ID
                            onChange={handleRoleChange} // Handle role change
                            required
                        >
                            {roles.map((role) => (
                                <MenuItem key={role._id} value={role._id}>
                                    {role.name} {/* Display role name but store role ID */}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    {/* No Outlet ID field is shown in UI, but it's still part of formData */}
                    
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            Add User
                        </Button>
                    </Grid>
                </Grid>
            </form>
            {error && <Typography color="error">{error}</Typography>}
            {success && <Typography color="primary">{success}</Typography>}
        </Container>
    );
};

export default AddUser;
