import React, { useEffect, useState } from 'react';
import {jwtDecode} from 'jwt-decode'; // Corrected import
import './navbar.scss';

const Navbar = () => {
  const [user, setUser] = useState({ username: '', role: '' });
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const storedToken = localStorage.getItem('token');

        const response = await fetch(`${apiURL}/users/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        // Capitalize the first letter of the role
        const roleName = data.role.name.charAt(0).toUpperCase() + data.role.name.slice(1);

        setUser({ username: data.username, role: roleName }); // Set the username and the capitalized role
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      const decodedToken = jwtDecode(storedToken);
      if (decodedToken && decodedToken.id) {
        fetchUserData(decodedToken.id);
      }
    }
  }, [apiURL]);

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="items">
          <div className="item">
            <span>{user.username}</span>
            <span> / {user.role}</span> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
